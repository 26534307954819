.GlobalEvaluationWrapper {
  color: #010b6c;
  padding: 0 1.5%;
  display: flex;
  justify-content: space-between;
}
.GlobalEvaluationButtonHeadWrapper {
  width: 70%;
}
.GlobalEvaluationThumbnailSpace {
  text-align: right;
  padding: 2.5%;
  width: 20%;
}
.GlobalEvaluationThumbnailSpace img {
  border-radius: 20px;
  width: 100%;
}
.GlobalEvaluationHeading {
  display: flex;
  align-items: center;
  margin: 2% 0 1% -5%;
}
.GlobalEvaluationButtons {
  display: flex;
  margin: 0 3%;
  justify-content: space-between;
}
.GlobalEvaluationButton1 {
  width: 55%;
}
.GlobalEvaluationButton2 {
  margin: 3% 3% 7% 3%;
  width: 35%;
}
.GlobalEvaluationHeading a {
  width: 5%;
}
.GlobalEvaluationHeading img {
  width: 100%;
}
.GlobalEvaluationFileInput {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 20%;
  padding: 0.6%;
  margin: -0.6% 0% 0% -15%;
}
.Clicked {
  background-color: #010b75;
}
.Clicked:hover {
  cursor: not-allowed;
}
.NotClicked {
  background-color: #2281ff;
}
.NotClicked:hover {
  cursor: pointer;
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .GlobalEvaluationHeading a {
    width: 8%;
  }
}
@media screen and (max-width: 540px) and (orientation: portrait) {
  .GlobalEvaluationHeading a {
    width: 11%;
  }
}

.EvaluationInstructionWrap{
  display: flex;
  align-items: center;
  justify-content: center;
}
.EvaluationInstruction {
  background-color: transparent;
  border-radius: 20px;
  padding: 2%;
  width: 86%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 20px 0px;
}
.ResultHeading {
  color: #2281ff;
  font-size: 16px;
  margin-bottom: 8px;
}
.ResultBody {
  color: #1d0e8b;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}
.ResultBody span{
  color: #1d0e8b;
  font-size: 12px;
  font-weight: 600;
}