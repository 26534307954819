.HeaderWrapper {
  padding: 2% 3%;
  display: flex;
  justify-content: space-between;
}
.HeaderLeftWrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.HeaderRightWrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.HeaderLeftWrapper img {
  width: 15%;
  margin: 0 3%;
}
.HeaderRightBellIcon {
  width: 11%;
  padding-right: 8%;
}
.HeaderRightProfileIcon {
  border: 2px solid #fff;
  box-shadow: 0px 3px 3px #cacaca;
  border-radius: 190px;
  cursor: pointer;
  width: 14%;
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .HeaderLeftWrapper img {
    width: 25%;
  }
  .HeaderRightWrapper img {
    width: 20%;
  }
  .HeaderLeftWrapper h1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 920px) and (orientation: portrait) {
  .HeaderWrapper {
    padding: 2% 5%;
  }
  .HeaderLeftWrapper {
    width: 60%;
  }
  .HeaderRightWrapper {
    width: 40%;
  }
  .HeaderLeftWrapper img {
    width: 30%;
  }
  .HeaderRightWrapper img {
    width: 35%;
  }
  .HeaderLeftWrapper h1 {
    font-size: 16px;
  }
}
