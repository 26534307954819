
.QuizPopupWrapper {
    box-shadow: 0px 3px 3px #cacaca;
    background-color: #bdd6f8;
    border: 2px solid #fff;
    text-align: left;
    border-radius: 20px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    word-wrap: break-word;
    width: 60%;
    max-height: 70%;
    min-height: 35%;
}

.QuizPopupHeader{
    width: 94%;
    padding: 2% 3%;
    background-color: #4898ff;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.QuizPopupHeader img{
    box-shadow: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 2%;
}
.QuizPopupQNAnswer{
    width: 94%;
    display: flex;
    padding: 2% 3%;
    justify-content: space-between;
}
.PlayerStylesheet{
    width: 94%;
    display: flex;
    padding: 2% 3%;
    justify-content: center;
}
.QuizPopupQNAnswer ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
}
.QuizPopupQNAnswer li {
    width: 80%;
    box-shadow: 0px 3px 3px #cacaca;
    background-color: #bdd6f8;
    border: 2px solid #fff;
    border-radius: 10px;
    font-weight: 600;
    padding: 2% 3%;
    cursor: pointer;
    margin-bottom: 2%;
    color: #010b6c;
}
.QuizPopupQNAnswer li:hover{
    background-color:#4898ff;
    color: white;
}
.selectedOption{
    background-color:#010b6c !important;
    color: white !important;
}
.QuizPopupQuestion{
    min-width: 50%;
    max-width: 50%;
}
.QuizPopupQuestionImage{
    min-width: 96%;
    max-width: 96%;
    margin: 4% 2%;
}
.QuizPopupQuestionImage img{
    min-width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: none;
    border: none;
    border-radius: 15px;
    cursor: context-menu;
    margin: 0;
    padding: 0;
}
.QuizPopupAnswerImage{
    min-width: 96%;
    max-width: 96%;
    margin: 4% 2%;
}
.QuizPopupAnswerImage img{
    min-width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: none;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
.QuizPopupButtons{
    width: 94%;
    padding: 0 3%;
    display: flex;
    justify-content: flex-end;
}
.QuizPopupButtons button{
    margin: 0;
    margin-bottom: 30px;
    width: fit-content;
    border-radius: 10px;
    padding: 1% 3%;
}
.QuizPopupQNResult{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.QuizPopupQNAnswer h3 {
    color: #010b6c;
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
}
.QuizPopupQNResult li:hover{
    background-color: #bdd6f8;
    color: #010b6c;
}
.correctOption{
    background-color: green !important;
    color: white !important;
}
.wrongOption{
    background-color: red !important;
    color: white !important;
}
.correctOption:hover{
    background-color: green !important;
    color: white !important;
}
.wrongOption:hover{
    background-color: red !important;
    color: white !important;
}
@media screen and (max-width: 920px){
    
    .QuizPopupWrapper {
        box-shadow: 0px 3px 3px #cacaca;
        background-color: #bdd6f8;
        border: 2px solid #fff;
        text-align: left;
        border-radius: 20px;
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        word-wrap: break-word;
        width: 80%;
        max-height: 85%;
        min-height: 35%;
    }
    .QuizPopupHeader{
        width: 88%;
        padding: 6%;
    }
    .QuizPopupQNAnswer{
        display: block;
        width: 88%;
        padding: 6%;
    }
    .QuizPopupQuestion{
        min-width: 100%;
        max-width: 100%;
    }
    .QuizPopupQNAnswer ul {
        padding: 3% 0;
        width: 100%;
    }
    .QuizPopupQNAnswer li{
        padding: 0;
        width: 88%;
        padding: 2% 6%;
    }
    .QuizPopupButtons{
        width: 88%;
        padding: 0 6%;
    }
    .QuizPopupButtons button{
        margin: 0;
        margin-bottom: 30px;
        width: fit-content;
        border-radius: 10px;
        padding: 10px 30px;
    } 
    .QuizPopupHeader img{
        width: 4%;
    }
}

@media screen and (max-width: 600px){
    .QuizPopupQNAnswer li{
        padding: 0;
        width: 88%;
        padding: 4% 6%;
    }
    .QuizPopupQNAnswer ul {
        padding: 6% 0;
        width: 100%;
    }
    .QuizPopupHeader img{
        width: 6%;
    }
}