body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  cursor: context-menu;
  background-color: #2281ff;
  background-size: cover;
  background-image: url("./assets/BackgroundComponent.png");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (max-width: 900px) and (orientation: landscape) {
  /* 900 used for landscape mobile screen */
  body {
    overflow: scroll;
  }
}
@media screen and (max-width: 920px) and (orientation: portrait) {
  /* 500 used for protrait pad screen */
  body {
    overflow: scroll;
    background-image: url("./assets/MobileBackgroundTop.png"),
      url("./assets/MobileBackgroundBottom.png");
    background-position: top right, bottom left;
    background-size: 100%, 100%;
    background-repeat: no-repeat, no-repeat;
  }
}
* {
  margin: 0px;
  padding: 0px;
}
body {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}
h1 {
  font-size: 36px;
  font-style: italic;
  font-family: "Roboto", sans-serif;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
}
h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
}
h4 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}
h5 {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-size: 20px;
}
h6 {
  font-size: 16px;
  font-weight: 700;
}
input {
  width: 95%;
  outline-color: #2281ff;
  font-size: 18px;
  padding: 2%;
  margin: 3% 0;
  border-radius: 5px;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
}
textarea {
  width: 98%;
  height: 80px;
  font-size: 18px;
  margin-top: 2%;
  padding: 1%;
  border-radius: 10px;
  border-width: 0px;
  outline-color: #2281ff;
  background: white;
  resize: none;
}
button {
  border: none;
  width: 99%;
  border-radius: 5px;
  padding: 2%;
  font-weight: 700;
  margin: 3% 0 7% 0;
  color: white;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  background-color: #2281ff;
  cursor: pointer;
}
button:hover {
  background-color: #010b6c;
}
@media screen and (max-width: 1370px) {
  body {
    font-size: 12px;
  }
  h1 {
    font-size: 21px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 13px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 10px;
  }
  input {
    font-size: 13px;
  }
  textarea {
    font-size: 13px;
  }
  button {
    font-size: 13px;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  h1 {
    font-size: 19px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 13px;
  }
  h4 {
    font-size: 10px;
  }
  h5 {
    font-size: 8px;
  }
  input {
    font-size: 12px;
  }
  textarea {
    font-size: 12px;
  }
  button {
    font-size: 12px;
  }
}
@media screen and (max-width: 660px) and (orientation: landscape) {
  body {
    font-size: 10px;
  }
  h1 {
    font-size: 19px;
  }
  h6 {
    font-size: 9px;
  }
  input {
    font-size: 10px;
  }
  textarea {
    font-size: 10px;
  }
  button {
    font-size: 10px;
  }
}
@media screen and (max-width: 920px) and (orientation: portrait) {
  h2 {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  h3 {
    font-size: 12px;
  }
  h4 {
    font-size: 11px;
  }
  h5 {
    font-size: 11px;
  }
  input {
    padding: 1%;
  }
}
