.HomeContainer {
  margin: 11vh 7vw;
  width: 86vw;
  height: 78vh;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  background-color: #e0edfe;
  align-items: center;
}

.HomeContextContainer {
  background-color: #e0edfe;
  border-radius: 20px;
  margin: 0 3%;
  padding: 0 2%;
  height: 73%;
  overflow-y: auto;
  overflow-x: hidden;
}

.HomeContainerConstructionImage {
  background-color: #e0edfe;
  border-radius: 20px;
  margin: 0 3%;
  height: auto;
  width: 94%;
}

.HomeContainer h1 {
  color: #010b6c;
}

.HomeRightSideWrapper h2 {
  color: #2281ff;
}

.HomeRightSideWrapper p {
  margin: 2% 0;
}

.HomeRightSideWrapper {
  width: 81%;
  height: 100%;
  border-radius: 50px;
  background-color: white;
}

.ZoomMeetingButton {
  width: 60%;
  margin: 2% 20% 5% 20%;
}

.ZoomMeetingGreetImage {
  width: 40%;
  margin: 5% 30% 0 30%;
}

.GlobalWindowWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.GlobalHomeMessageArrayWrapper {
  position: absolute;
  z-index: 1000;
  margin-bottom: 20px;
}

.GlobalHomeSuccessMessageArray {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #71afff;
  padding: 13px 25px;
  margin: 11px 25px;
  font-weight: 700;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 1px 1px #6262628d;
  color: #00295f;
  animation: show 0.5s forwards;
}

.GlobalHomeErrorMessageArray {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ff95a7;
  padding: 13px 25px;
  margin: 11px 25px;
  font-weight: 700;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 1px 1px #6262628d;
  color: #6C0113;
  animation: show 0.5s forwards;
}

@media screen and (max-width: 920px) and (orientation: landscape) {
  .HomeContainer {
    border-radius: 25px;
  }

  .HomeRightSideWrapper {
    border-radius: 25px;
    width: 76%;
  }
}

@media screen and (max-width: 920px) and (orientation: portrait) {
  .HomeContainer {
    display: block;
    height: auto;
    border-radius: 25px;
  }

  .HomeRightSideWrapper {
    width: 98%;
    height: auto;
    padding: 3% 1%;
    border-radius: 25px;
  }
}

@keyframes show {
  0% {
    transform: translate(-15px, 15px) scale(0);
  }

  50% {
    transform: translate(-15px, 15px) scale(1.2);
  }

  70% {
    transform: translate(-15px, 15px) scale(0.9);
  }

  100% {
    transform: translate(-15px, 15px) scale(1);
  }
}