.GlobalProfileDetailsList input {
  margin: 2% 0;
}
.GlobalProfileDetails {
  overflow-wrap: break-word;
  color: #010b6c;
  float: left;
  width: 26%;
  margin: 3% 1%;
}
.GlobalProfileDetailsList img {
  width: 50%;
  margin-bottom: 3%;
  border: 2px solid #fff;
  box-shadow: 0px 3px 3px #cacaca;
  border-radius: 190px;
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .GlobalProfileDetails {
    width: 94%;
    padding: 1% 3%;
  }
  .GlobalProfileDetailsList img {
    display: none;
  }
}
