.AuthContainer {
  margin: 15vh 7vw;
  width: 86vw;
  height: 70vh;
  display: flex;
  border-radius: 50px;
  justify-content: center;
  background-color: #e0edfe;
  align-items: center;
}
.AuthContainerBold {
  font-weight: 700;
}
.AuthContainer a {
  color: #2281ff;
}
.AuthContainer a:hover {
  color: #010b6c;
}
.RightComponentBacktoFont {
  color: #b6b6b6;
  text-align: center;
  margin: -6% 0 6% 0;
}
.AuthContainer input.invalid::placeholder {
  color: #ff0000;
}
.AuthContainer h1 {
  margin: 0 0 0.5vw 0;
}
.LeftComponentWrapper {
  width: 50%;
  height: 100%;
}
.LeftComponentLogo {
  padding-top: 2vh;
  display: flex;
  justify-content: center;
}
.LeftComponentLogo img {
  width: 15vw;
}
.LeftComponentWelcome {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LeftComponentWelcome img {
  width: 26vw;
}
.LeftComponentWelcome video {
  width: 26vw;
}
.RightComponentLoginFields {
  width: 50%;
  height: 100%;
  border-radius: 50px;
  background-color: white;
  color: #010b6c;
}
.RightComponentHeading {
  font-style: italic;
  margin: 6%;
}
.RightComponentInputFields {
  margin: 0 6%;
}
.RightComponentCheckbox {
  width: 55%;
  display: flex;
  align-items: center;
}
.RightComponentCheckbox input {
  width: 7%;
  margin-right: 2px;
  cursor: pointer;
}
.RightComponentForgetPassWrapper {
  display: flex;
  padding-bottom: 3%;
  justify-content: space-between;
}
.RightComponentForgetPassword {
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1300px) {
  .AuthContainer {
    font-size: 13px;
  }
}
@media screen and (max-width: 1180px) {
  .RightComponentInputFields {
    display: flex;
    height: 70%;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .AuthContainer {
    margin: 8vh 7vw;
    height: 84vh;
    border-radius: 25px;
    font-size: 12px;
  }
  .LeftComponentWelcome img {
    width: 33vw;
  }
  .LeftComponentWelcome video {
    width: 33vw;
  }
  .RightComponentLoginFields {
    border-radius: 25px;
  }
}
@media screen and (max-width: 660px) and (orientation: landscape) {
  .AuthContainer {
    font-size: 10px;
  }
}
@media screen and (max-width: 920px) and (orientation: portrait) {
  .AuthContainer {
    flex-direction: column;
    margin: 10vh 7vw;
    height: 80vh;
  }
  .LeftComponentLogo img {
    width: 18vh;
  }
  .LeftComponentWelcome img {
    width: 29vh;
  }
  .LeftComponentWelcome video {
    width: 29vh;
  }
  .RightComponentLoginFields {
    width: 100%;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .AuthContainer {
    border-radius: 25px;
  }
  .RightComponentLoginFields {
    border-radius: 25px;
  }
}
