.ProfileManageDescriptionWrapper {
  float: left;
  width: 70%;
  margin: 1%;
}
.ProfileManageDescription {
  margin: 2%;
  padding: 2%;
  color: #010b6c;
  background-color: #bdd6f8;
  border-radius: 20px;
}
.ProfileManageDescription input {
  padding: 1%;
  margin: 1% 0;
}
.ProfileManageDescription ul {
  margin: 2%;
}
.ProfileManageDescription li {
  margin: 0;
  display: flex;
}
.ProfileManageDescription img {
  margin: 0 2%;
  width: 5%;
}
.ProfileManageAttachButton {
  width: 99%;
  margin: 2% 0;
  background-color: #a1a1a1;
}
.ProfileManageAttachButton:hover {
  background-color: gray;
}
.ProfileManageAttachButton input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 15%;
  padding: 0.3%;
  margin: -0.3% 0 0 -13.5%;
}
.ProfileManageDescriptionHeadingBioWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProfileManageDescriptionHeadingBioWrapper button {
  width: 20%;
  padding: 1%;
  margin: 0;
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .ProfileManageAttachButton input {
    margin: -2% 0 0 -12%;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .ProfileManageDescriptionWrapper {
    width: 92%;
    padding: 0 3%;
  }
  .ProfileManageDescription {
    margin: 3% 0;
    padding: 5%;
  }
  .ProfileManageDescription img {
    width: 10%;
  }
  .ProfileManageAttachButton input {
    margin: -0.6% 0 0 -52%;
    width: 62%;
  }
}
