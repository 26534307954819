.GlobalEvaluationWrapper {
    color: #010b6c;
    padding: 0 1.5%;
    display: flex;
    justify-content: space-between;
}
.GlobalEvaluationButtonHeadWrapper {
    width: 70%;
}
.GlobalEvaluationHeading {
    display: flex;
    align-items: center;
    margin: 2% 0 1% -5%;
}
.GlobalEvaluationHeading a {
    width: 5%;
}
.GlobalEvaluationHeading img {
    width: 100%;
}
.DocumentScreenParser{
    padding: 0 1.5%;
    color: #010b6c;
}
.DocumentScreenParser img{width: 100%;}
.DocumentScreenParser .icon {width: 100px; height: auto; float: left; display: flex; padding-right: 10px;}
.DocumentScreenParser p{font-size: 14px; line-height: 1.4em; margin: 0px; padding: 5px 0px;}
.DocumentScreenParser span.left{float: left; width: 24%; padding: 0px 10px 5px 0px;}
.DocumentScreenParser .content-container{float: left; width: 100%; padding: 0px;}
.DocumentScreenParser span.right{float: right; width: 24%; padding: 0px 0px 5px 10px; background:none;}
.DocumentScreenParser ul{line-height: 1.4em; margin: 0px; padding: 10px 20px; float: left;}
.DocumentScreenParser ol{line-height: 1.4em; margin: 0px; padding: 10px 15px; float: left;}
hr{width: 100%; border:1px #2281ff solid;}
.DocumentScreenParser h1{
    font-size: 28px;
    font-style: normal;
    color: #010b6c;
}
.DocumentScreenParser h2{
    font-size: 24px;
    color: #010b6c; line-height: 1.4em; padding: 10px 0px 5px 0px ;
}
.DocumentScreenParser h3{
    font-size: 20px;
    color: #010b6c; line-height: 1.4em; padding: 10px 0px 5px 0px ;
}
.DocumentScreenParser h4{
    font-size: 18px;
    color: #010b6c; line-height: 1.4em; padding: 10px 0px 5px 0px ;
}
.DocumentScreenParser li ul, .DocumentScreenParser li ol{padding:5px 30px;}
.DocumentScreenParser li{padding:5px 0px; float: left;font-size: 14px; line-height: 1.4em;}
.DocumentScreenParser b{font-size: 16px;}
li::marker{font-size: 16px; font-weight: bold;}
.DocumentScreenButton{
    width: fit-content;
    margin: 2% 4% 6% 4%;
    padding: 10px 20px;
    float: right;
}

@media screen and (max-width: 1370px) {
    .DocumentScreenParser h1 {
        font-size: 18px;
    }
    .DocumentScreenParser h2{
        font-size: 16px;
    }
}

@media screen and (max-width: 920px) and (orientation: landscape) {
    .GlobalEvaluationHeading a {
      width: 8%;
    }
    .DocumentScreenParser h1{
        font-size: 18px;
    }
    .DocumentScreenParser h2{
        font-size: 13px;
    }
}

@media screen and (max-width: 920px) and (orientation: portrait) {
    .DocumentScreenParser h2 {
        font-size: 13px;
    }
    .DocumentScreenParser span.left, .DocumentScreenParser span.right{width: 100%; padding: 0px;}

  }

@media screen and (max-width: 540px) and (orientation: portrait) {
    .GlobalEvaluationHeading a {
      width: 11%;
    }
    .DocumentScreenParser span.left, .DocumentScreenParser span.right{width: 100%; padding: 0px;}
    .DocumentScreenParser img {width: 100% !important; height: auto !important;}
}