.DashboardScreenContainer {
  margin: 0 3%;
  display: flex;
  justify-content: space-between;
}
.DashboardScreenContainer p {
  font-size: 16px;
}
.DashboardScreenWrapper {
  background-color: #e0edfe;
  border-radius: 20px;
  color: #010b6c;
  padding: 0 2% 2% 2%;
  width: 45%;
  overflow: hidden;
}
.DashboardScreenWrapper h2 {
  padding: 3% 0;
}
.DashboardScreenCourseHeading {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.DashboardScreenBookCover {
  width: 35%;
}
.DashboardScreenBookCover img {
  border-radius: 20px;
  width: 100%;
}
.DashboardScreenCourseWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DashboardScreenIconWrapper {
  cursor: pointer;
  width: 6%;
}
.DashboardScreenCourses {
  background-color: #bdd6f8;
  border-radius: 20px;
  padding: 5%;
  display: flex;
  width: 90%;
  cursor: pointer;
  white-space: initial;
}
.DashboardScreenStarRatings {
  display: flex;
  align-items: center;
  width: 10%;
}
.DashboardScreenStarRatings img {
  width: 23px;
}
.DashboardScreenStarRatings p {
  padding: 0 5px;
}
.DashboardScreenDescription {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 3%;
}
.DashboardScreenProgressBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DashboardScreenProgressBars {
  width: 86%;
  margin: 5% 0;
  background-color: #010b6c;
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
}
.DashboardScreenProgressBar1 {
  border: 3px solid white;
  border-radius: 1px 1px 1px 1px;
  margin: 0;
}

.DashboardScreenWrapper label {
  transition: transform 400ms ease-out;
  display: inline-block;
  min-height: 100%;
  width: 97%;
  position: relative;
  z-index: 1;
  margin-right: 3%;
  margin-top: 3%;
}

.DashboardScreenWrapper form {
  width: 100%;
  white-space: nowrap;
}
.DashboardScreenWrapper input {
  position: absolute;
}

.keys {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  color: #fff;
  text-align: center;
  transition: all 300ms linear;
  opacity: 0;
}

.DashboardScreenWrapper input:focus ~ .keys {
  opacity: 0.8;
}

.DashboardScreenWrapper input:nth-of-type(1):checked ~ label:nth-of-type(1),
.DashboardScreenWrapper input:nth-of-type(2):checked ~ label:nth-of-type(2),
.DashboardScreenWrapper input:nth-of-type(3):checked ~ label:nth-of-type(3),
.DashboardScreenWrapper input:nth-of-type(4):checked ~ label:nth-of-type(4) {
  z-index: 0;
}

.DashboardScreenWrapper input:nth-of-type(1):checked ~ label {
  transform: translate3d(0, 0, 0);
}

.DashboardScreenWrapper input:nth-of-type(2):checked ~ label {
  transform: translate3d(-100%, 0, 0);
}

.DashboardScreenWrapper input:nth-of-type(3):checked ~ label {
  transform: translate3d(-200%, 0, 0);
}

.DashboardScreenWrapper input:nth-of-type(4):checked ~ label {
  transform: translate3d(-300%, 0, 0);
}

.DashboardScreenWrapper label {
  background-size: cover;
}

.DashboardScreenWrapper label[for="diamonds"],
.DashboardScreenWrapper label[for="hearts"] {
  background: #cc0000;
}

.DashboardScreenWrapper label:before,
.DashboardScreenWrapper label:after {
  color: white;
  display: block;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  padding: 1rem;
  font-size: 3rem;
  height: 10rem;
  line-height: 10rem;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  cursor: pointer;
}

.DashboardScreenWrapper label:before {
  content: "\276D";
  right: 100%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.DashboardScreenWrapper label:after {
  content: "\276C";
  left: 100%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

@media screen and (max-width: 1370px) {
  .DashboardScreenContainer p {
    font-size: 10px;
  }
  .DashboardScreenStarRatings img {
    width: 15px;
  }
  .DashboardScreenProgressBar1 {
    border: 2px solid white;
  }
  .DashboardScreenProgressBar2 {
    border: 2px solid #010b6c;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .DashboardScreenWrapper {
    max-height: 65%;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .DashboardScreenContainer {
    display: block;
  }
  .DashboardScreenWrapper {
    padding: 0 3% 3% 3%;
    margin-bottom: 3%;
    width: 94%;
  }
}
@media screen and (max-width: 660px) and (orientation: landscape) {
  .DashboardScreenContainer p {
    font-size: 9px;
  }
}
