.RightComponentNotFoundWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.RightComponentNotFoundPara{
  text-align: center;
  margin: 6%;
}
.RightComponentNotFoundButton {
  margin: 0 9%;
  display: flex;
  justify-content: center;
}
.RightComponentNotFoundButton button{
  width: 36%;
  margin: 0 3%;
}
