.SidebarWrapper {
  width: 19%;
  height: 100%;
  font-weight: 700;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}
.SidebarDropdownMenu {
  display: none;
}
.SidebarLogoWrapper {
  text-align: center;
  display: block;
  margin: 15%;
}
.SidebarLogoWrapper img {
  width: 100%;
}
.SidebarMenuComponent li {
  display: flex;
  align-items: center;
  padding: 5% 12%;
  color: #010b6c;
}
.MenuItemsLogout {
  margin-top: 33%;
}
.SideBarDataIcons {
  width: 20px;
  padding: 0 5%;
}
.SidebarMenuComponent .Active {
  background-color: white;
  color: #2281ff;
}
@media screen and (max-width: 1370px) {
  .SidebarWrapper {
    font-size: 13px;
  }
  .MenuItemsLogout {
    margin-top: 23%;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .SidebarWrapper {
    width: 24%;
    font-size: 11px;
    overflow-y: hidden;
  }
  .MenuItemsLogout {
    margin-top: 0%;
  }
  .SideBarDataIcons {
    width: 14px;
  }
}
@media screen and (max-width: 920px) and (orientation: portrait) {
  .SidebarWrapper {
    width: 100%;
    height: 30%;
    padding-bottom: 3%;
  }
  .SidebarLogoWrapper {
    margin: 2% 25%;
  }
  .SidebarMenuComponent {
    display: none;
  }
  .MenuItemsLogout {
    margin-top: 0%;
  }
  .SidebarButtonDropDown img {
    width: 3%;
  }
  .SidebarDropdownContent li {
    color: white;
    padding: 3% 12%;
    margin-left: 0px;
  }
  .SidebarDropdownContent .Active {
    background-color: white;
    color: #2281ff;
  }
  .SidebarDropdownMenu {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .SidebarButtonDropDown {
    background-color: #2281ff;
    border-radius: 10px;
    color: white;
    padding: 3% 16px;
    width: 90%;
    margin: 0 5%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .SidebarDropdownContent {
    display: none;
    position: absolute;
    background-color: #010b6c;
    width: 84%;
    margin: 0 8%;
    padding: 5% 0;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 8px 16px 0px #00000033;
  }
  .Pressed .SidebarDropdownContent {
    display: block;
  }
  .Pressed .SidebarButtonDropDown {
    background-color: #010b6c;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .SidebarButtonDropDown img {
    width: 5%;
  }
}
