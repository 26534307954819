.CoursesScreenStatusButtons {
  background-color: #bdd6f8;
  border-radius: 48px;
  margin: 1% 0;
}
.CoursesScreenHeading {
  padding: 2% 0 1% 0;
}
.CoursesScreenButton {
  animation: button-animation 60ms ease-in-out;
  background-color: #bdd6f8;
  border-radius: 48px;
  color: #030d6d;
  width: 50%;
  padding: 1%;
  margin: 0;
}
.Switched {
  animation: switched-animation 60ms ease-in-out;
  background-color: #010b6c;
  border-radius: 48px;
  color: #bdd6f8;
  width: 50%;
  padding: 1%;
  margin: 0;
}
.CoursesScreenButton:hover {
  background-color: #bdd6f8;
  color: #030d6d;
}
.Switched:hover {
  background-color: #010b6c;
  color: #bdd6f8;
}
.CoursesScreenCoursesList {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-items: center;
}
.CoursesCardsWrapper {
  background-color: #bdd6f8;
  width: 90%;
  margin: 2%;
  padding: 6% 2%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 20px;
}
.CoursesCardsWrapper img {
  cursor: pointer;
  width: 86%;
  margin: 0 0 6% 0;
  border-radius: 25px;
}
.CoursesCardsDescription1 {
  width: 86%;
  color: #010b6c;
  display: flex;
  text-align: center;
  margin-bottom: 6%;
  align-items: center;
  justify-content: space-between;
}
.CoursesCardsDescription2 {
  width: 86%;
  color: #010b6c;
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.CoursesCardsWrapper h6 {
  text-transform: capitalize;
}
.CoursesCardsContinue {
  padding: 5px 10px;
  background-color: #010b6c;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
}
.CoursesCardsVideosCount {
  display: flex;
}
.CoursesCardsVideosCount img {
  width: 20%;
  padding: 3% 5%;
}
.CoursesCardsDescription2 > h6 {
  width: 30%;
  text-align: right;
}
.CoursesCardsProgressBars {
  width: 86%;
  margin: 0 0 8% 0;
  background-color: #010b6c;
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
}
.CoursesCardsProgressBar1 {
  border: 3px solid white;
  border-radius: 1px 1px 1px 1px;
  margin: 0;
}
@media screen and (max-width: 1370px) {
  .CoursesCardsDescription1 {
    display: block;
  }
  .CoursesCardsContinue {
    margin-top: 6%;
  }
  .CoursesScreenButton {
    font-size: 13px;
  }
  .CoursesCardsProgressBar1 {
    border: 2px solid white;
  }
  .CoursesCardsProgressBar2 {
    border: 2px solid #010b6c;
  }
  .CoursesCardsVideosCount img {
    padding: 1% 5%;
  }
}
.wrapper{
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle{
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
}
@keyframes loading{
  0%{
    transform: translateY(0px);
    background-color: white;
  }
  50%{
    transform: translateY(50px);
    background-color: #010b6c;
  }
  100%{
    transform: translateY(0px);
    background-color: white;
  }
}
.circle-1{
  animation-delay: 0.1s;
}
.circle-2{
  animation-delay: 0.2s;
}
.circle-3{
  animation-delay: 0.3s;
}
.circle-4{
  animation-delay: 0.4s;
}
.circle-5{
  animation-delay: 0.5s;
}
.circle-6{
  animation-delay: 0.6s;
}
.circle-7{
  animation-delay: 0.7s;
}
.circle-8{
  animation-delay: 0.8s;
}


@media screen and (max-width: 740px) {
  .CoursesCardsDescription2 {
    display: none;
  }
  .wrapper{
    display: none;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .CoursesScreenCoursesList {
    grid-template-columns: repeat(3, 33%);
  }
}
@media screen and (max-width: 540px) and (orientation: portrait) {
  .CoursesScreenCoursesList {
    grid-template-columns: repeat(2, 50%);
  }
}
@media screen and (max-width: 280px) and (orientation: portrait) {
  .CoursesScreenCoursesList {
    grid-template-columns: repeat(1, 100%);
  }
}
@keyframes button-animation {
  0% {
    width: 20%;
  }
  100% {
    width: 50%;
  }
}
@keyframes switched-animation {
  0% {
    width: 80%;
  }
  100% {
    width: 50%;
  }
}

.alertify,
.alertify-show,
.alertify-log {
  -webkit-transition: all 500ms
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */
}

.alertify-hide {
  -webkit-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* easeInBack */
}

.alertify-log-hide {
  -webkit-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* easeInBack */
}

.alertify-cover {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  filter: alpha(opacity=0);
  opacity: 0;
}

.alertify-cover-hidden {
  display: none;
}

.alertify {
  position: fixed;
  z-index: 99999;
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px;
  opacity: 1;
}

.alertify-hidden {
  -webkit-transform: translate(0, -150px);
  -moz-transform: translate(0, -150px);
  -ms-transform: translate(0, -150px);
  -o-transform: translate(0, -150px);
  transform: translate(0, -150px);
  opacity: 0;
  display: none;
}

/* overwrite display: none; for everything except IE6-8 */
:root * > .alertify-hidden {
  display: block;
  visibility: hidden;
}

.alertify-logs {
  position: fixed;
  z-index: 5000;
  bottom: 10px;
  right: 10px;
  width: 300px;
}

.alertify-logs-hidden {
  display: none;
}

.alertify-log {
  display: block;
  margin-top: 10px;
  position: relative;
  right: -300px;
  opacity: 0;
}

.alertify-log-show {
  right: 0;
  opacity: 1;
}

.alertify-log-hide {
  -webkit-transform: translate(300px, 0);
  -moz-transform: translate(300px, 0);
  -ms-transform: translate(300px, 0);
  -o-transform: translate(300px, 0);
  transform: translate(300px, 0);
  opacity: 0;
}

.alertify-dialog {
  padding: 25px;
}

.alertify-resetFocus {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.alertify-inner {
  text-align: center;
}

.alertify-text {
  margin-bottom: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

.alertify-button,
.alertify-button:hover,
.alertify-button:active,
.alertify-button:visited {
  background: none;
  text-decoration: none;
  border: none;
  /* line-height and font-size for input button */
  line-height: 1.5;
  font-size: 100%;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
}

@media only screen and (max-width: 680px) {
  .alertify,
  .alertify-logs {
    width: 90%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .alertify {
    left: 5%;
    margin: 0;
  }
}
/**
 * Default Look and Feel
 */
.alertify,
.alertify-log {
  font-family: sans-serif;
}

.alertify {
  background: #fff;
  border: 10px solid #333;
  /* browsers that don't support rgba */
  border: 10px solid rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding;
  /* Safari 4? Chrome 6? */
  -moz-background-clip: padding;
  /* Firefox 3.6 */
  background-clip: padding-box;
  /* Firefox 4, Safari 5, Opera 10, IE 9 */
}

.alertify-text {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.alertify-button {
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
}

.alertify-button:hover,
.alertify-button:focus {
  outline: none;
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1),
    transparent
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  );
}

.alertify-button:focus {
  box-shadow: 0 0 15px #2b72d5;
}

.alertify-button:active {
  position: relative;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
.alertify-button-cancel,
.alertify-button-cancel:hover,
.alertify-button-cancel:focus {
  background-color: #fe1a00;
  border: 1px solid #d83526;
}

.alertify-button-ok,
.alertify-button-ok:hover,
.alertify-button-ok:focus {
  background-color: #5cb811;
  border: 1px solid #3b7808;
}

.alertify-log {
  background: #1f1f1f;
  background: rgba(0, 0, 0, 0.9);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}

.alertify-log-error {
  background: #fe1a00;
  background: rgba(254, 26, 0, 0.9);
}

.alertify-log-success {
  background: #5cb811;
  background: rgba(92, 184, 17, 0.9);
}