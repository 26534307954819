.BugReportScreenLoginFields input {
  margin: 2% 0;
}
.BugReportLeftImgWelcome {
  float: left;
  width: 48%;
  padding: 4% 0;
  text-align: center;
}
.BugReportLeftImgWelcome img {
  width: 80%;
}
.BugReportScreenLoginFields {
  float: left;
  width: 48%;
  height: 89%;
  margin: 1%;
  padding: 1%;
  border-radius: 20px;
  background-color: white;
  color: #010b6c;
}
.BugReportScreenLoginFields h3 {
  margin: 3% 6%;
}
.BugReportScreenInputFields {
  margin: 0 5%;
  text-align: center;
}
.BugReportScreenSubmitButton {
  width: 80%;
  margin: 2% 0;
}
.BugReportScreenScreenShotButton {
  width: 99%;
  margin: 2% 0;
  background-color: #a1a1a1;
}
.BugReportScreenScreenShotButton:hover {
  background-color: gray;
}
.BugReportScreenScreenShotButton input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 25%;
  padding: 0.6%;
  margin: -0.6% 0 0 -19%;
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .BugReportScreenLoginFields {
    overflow-y: scroll;
  }
  .BugReportScreenScreenShotButton input {
    margin: -0.6% 0 0 -22%;
    width: 24%;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .BugReportLeftImgWelcome {
    width: 100%;
  }
  .BugReportScreenLoginFields {
    margin: 3% 1%;
    width: 96%;
  }
  .BugReportScreenScreenShotButton input {
    margin: -0.6% 0 0 -52%;
    width: 62%;
  }
}
