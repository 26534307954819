.LessonsCreenDescThumbnailWrapper {
  padding: 0 1.5%;
  display: flex;
  color: #010b6c;
  justify-content: space-between;
}
.LessonsScreenHeading {
  display: flex;
  align-items: center;
  margin: 2% 0 1% -5%;
}
.LessonsScreenHeading h2 {
  padding: 0;
}
.LessonsScreenHeading a {
  width: 5%;
}
.LessonsScreenHeading img {
  width: 100%;
}
.LessonsScreenVideosCount {
  width: 30%;
  display: flex;
  font-weight: 700;
}
.LessonsScreenVideosCount img {
  width: 10%;
  padding: 0 5% 0 0;
}
.LessonsScreenWholeDescription {
  width: 70%;
}
.LessonsScreenDownloadBook {
  padding: 5px 10px;
  background-color: #010b6c;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 700;
  width: fit-content;
  float: left;
}
.LessonsScreenThumbnailSpace {
  text-align: right;
  padding-top: 2.5%;
  width: 20%;
}
.LessonsScreenThumbnailSpace img {
  border-radius: 20px;
  width: 100%;
}
.LessonsScreenCourseProgress {
  font-weight: 700;
  display: flex;
  color: #010b6c;
  padding: 0 1.5%;
  justify-content: space-between;
}
.LessonsScreenLessonsHeading {
  padding: 0 1.5%;
  color: #010b6c;
}
.LessonsScreenLessonsHeading h2 {
  padding: 2% 0 1% 0;
}
.LessonsScreenCardsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-items: center;
}
.LessonsCardsButtonThumbnailWrapper {
  background-color: #bdd6f8;
  border-radius: 20px;
  height: fit-content;
  margin: 2%;
  width: 90%;
}
.LessonsCardsButtonThumbnailBlocked {
  background-color: #bdd6f8;
  border-radius: 20px;
  filter: opacity(50%);
  cursor: no-drop;
  height: fit-content;
  margin: 2%;
  width: 90%;
}
.LessonsCardsButtonThumbnailBlocked a {
  cursor: no-drop;
}
.LessonCardsThumbnailsDivision img {
  border-radius: 20px 20px 0 0;
  width: 100%;
}
.LessonCardsButtonWrapper {
  padding: 3%;
  text-align: center;
  font-weight: 700;
}
.LessonCardsButtonWrapper p {
  margin: 3%;
}
.LessonCardsPlayButton {
  padding: 4%;
  background-color: #010b6c;
  border-radius: 20px;
  color: #ffffff;
}
.LessonsScreenProgressBars {
  margin: 0 1.5%;
  background-color: #010b6c;
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
}
.LessonsScreenProgressBar1 {
  border: 3px solid white;
  border-radius: 1px 1px 1px 1px;
  margin: 0;
}
.LessonPlayerPopupWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  top: 0;
  left: 0;
}
.LessonPlayerPopupWrapper img {
  box-shadow: 0px 3px 3px #cacaca;
  background-color: #010b6c;
  border: 2px solid #fff;
  border-radius: 100px;
  cursor: pointer;
  margin: 20px;
  padding: 7px;
  width: 28px;
}
.LessonPlayerPopupCard {
  box-shadow: 0px 3px 3px #cacaca;
  background-color: #bdd6f8;
  border: 2px solid #fff;
  text-align: left;
  border-radius: 20px;
  padding: 20px;
  width: 50%;
  height: auto;
  overflow-y: auto;
  word-wrap: break-word;
}
.LessonPlayerPopupCard button {
  width: 40%;
  margin: 1.6% 5%;
  padding: 1.6%;
}
.LessonPlayerPopupCard ul {
  margin: 3%;
}
@media screen and (max-width: 1370px) {
  .LessonsScreenProgressBar1 {
    border: 2px solid white;
  }
  .LessonsScreenProgressBar2 {
    border: 2px solid #010b6c;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .LessonsScreenCardsWrapper {
    grid-template-columns: repeat(3, 33%);
  }
  .LessonsScreenHeading a {
    width: 8%;
  }
}
@media screen and (max-width: 540px) and (orientation: portrait) {
  .LessonsScreenThumbnailSpace {
    display: none;
  }
  .LessonsScreenWholeDescription {
    width: 100%;
  }
  .LessonsScreenDownloadBook {
    margin: 1% 0;
  }
  .LessonsScreenCardsWrapper {
    grid-template-columns: repeat(2, 50%);
  }
  .LessonsScreenVideosCount {
    width: 50%;
  }
  .LessonsScreenHeading a {
    width: 11%;
  }
}
@media screen and (max-width: 280px) and (orientation: portrait) {
  .LessonsScreenCardsWrapper {
    grid-template-columns: repeat(1, 100%);
  }
}
