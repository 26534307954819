.ProfileScreenDetailsSocial {
  margin-top: 8%;
}
.ProfileScreenDetailsSocial img {
  cursor: pointer;
  width: 10%;
  margin-right: 10%;
}
.ProfileScreenDescription {
  width: 66%;
  margin: 3% 0;
  padding: 2%;
  float: left;
  color: #010b6c;
  background-color: #bdd6f8;
  border-radius: 20px;
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .ProfileScreenDescription {
    width: 88%;
    padding: 3%;
    margin: 3%;
  }
}
