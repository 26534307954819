.Result {
  background-color: white;
  border-radius: 20px;
  padding: 2%;
  width: 96%;
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}
.Prediction,
.MasterNotes {
  color: #1d0e8b;
  display: block;
  width: 100%;
  float: left;
  margin-top: 8px;
}

.NoteLists {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

hr {
  margin: 1.5% 0;
}
.progressBar {
  width: 100%;
  margin: 1% 0;
  height: 8px;
  border-radius: 8px;
  background-color: #010b6c;
  position: relative;
}
.progressBar::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: var(--width);
  height: 4px;
  border-radius: 8px;
  background-color: white;
}
.note {
  padding: 4px 6px;
  border-radius: 7px;
  margin: 8px 0px 0px 5px;
  background-color: #5c9aec;
  color: white;
}
.ResultHeading {
  color: #2281ff;
}
.wrong {
  background-color: #e86767;
}
.right {
  background-color: #70c26f;
}
.EvaluationResultCardUserScore {
  text-align: center;
  font-size: 30px;
}
.EvaluationResultCardProgressBars {
  background-color: white;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 4px #00000025;
  margin: 0% 4% 0% 3%;
}
.EvaluationResultCardProgressBar1 {
  border: 6px solid #70c26f;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 4px #00000025;
}
.EvaluationResultCardComment {
  color: #12128f;
  text-align: center;
}
.EvaluationResultCardComment span {
  color: #2281ff;
}
.EvaluationResultCardFontSize {
  font-size: 21px;
}
@media screen and (max-width: 1280px) {
  .EvaluationResultCardProgressBar1 {
    border: 4px solid #70c26f;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 4px #00000025;
  }
  .Result {
    height: 80%;
    width: 96%;
  }

  .progressBar {
    width: 100%;
  }
}

@media screen and (max-width: 1370px) {
  .EvaluationResultCardUserScore {
    font-size: 20px;
  }
  .EvaluationResultCardFontSize {
    font-size: 14px;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .EvaluationResultCardUserScore {
    font-size: 13px;
  }
  .EvaluationResultCardFontSize {
    font-size: 13px;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .EvaluationResultCardUserScore {
    font-size: 12px;
  }
  .EvaluationResultCardFontSize {
    font-size: 12px;
  }
}
