.ManageAccountWrapper {
  position: absolute;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #dadada;
  background-color: white;
  margin: 5% 5% 0% 0%;
  width: 25%;
}
.ManageAccountWrapper hr {
  border: 1px solid #dadada;
}
.ManageAccountWrapper img {
  width: 20%;
  margin: 0 2%;
}
.ManageAccountLogoEmailWrapper {
  display: flex;
  padding: 2%;
  overflow-wrap: break-word;
  align-items: center;
}
.ManageAccountLogoEmailWrapper img{
  border: 2px solid #fff;
  box-shadow: 0px 3px 3px #cacaca;
  border-radius: 190px;
}
.ManageAccountNameEmailWrapper {
  width: 80%;
}
.ManageAccountButtonWrapper {
  display: flex;
  padding: 2%;
}
.ManageAccountButtonWrapper button {
  background-color: white;
  border-radius: 10px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  color: black;
  margin: 3%;
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .ManageAccountWrapper {
    margin: 15% 6% 0% 0%;
    width: 30%;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .ManageAccountWrapper {
    margin: 15% 6% 0% 0%;
    width: 35%;
  }
}
@media screen and (max-width: 740px) and (orientation: landscape) {
  .ManageAccountWrapper {
    margin: 15% 6% 0% 0%;
    width: 45%;
  }
}
@media screen and (max-width: 920px) and (orientation: portrait) {
  .ManageAccountWrapper {
    margin: 5% 13% 0% 0%;
    width: 45%;
  }
}
@media screen and (max-width: 540px) and (orientation: portrait) {
  .ManageAccountWrapper {
    margin: 5% 13% 0% 0%;
    width: 58%;
  }
}
@media screen and (max-width: 500px) and (orientation: portrait) {
  .ManageAccountWrapper {
    margin: 50% 0% 0% 0%;
    width: 78%;
  }
}
@media screen and (max-width: 360px) and (orientation: portrait) {
  .ManageAccountWrapper {
    margin: 50% 0% 0% 0%;
    width: 83%;
  }
}
